import ky, { HTTPError, Options } from "ky"

// const isDev = process.env.NODE_ENV === 'development'

const DATE_REGEX =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/

const isDateString = (value: string | number | null): boolean => {
  if (value == null || typeof value !== "string") {
    return false
  }
  return DATE_REGEX.test(value)
}

const dateParser = (_: string, value: string): string | Date => {
  if (isDateString(value)) {
    // if so, Date() can parse it:
    return new Date(value)
  }
  return value
}

const api = ky.extend({
  prefixUrl: "/api/",
  credentials: "include",
})

const handleError = async (error: unknown): Promise<unknown> => {
  try {
    if (error instanceof HTTPError) {
      const resp = await error.response.json()
      console.warn("resp :", resp)

      return resp
    }
  } catch (_) {
    return error
  }
}

const handleResponse = async <T>(resp: Response): Promise<T> => {
  const respAsText = await resp.text()
  if (respAsText) return JSON.parse(respAsText, dateParser)
  return null as unknown as T
}

const get = async <T>(url: string, options?: Options): Promise<T> => {
  try {
    const resp = await api.get(url, options)
    return await handleResponse(resp)
  } catch (error) {
    throw await handleError(error)
    // throw error
  }
}
const post = async <T>(url: string, payload?: object): Promise<T> => {
  try {
    const resp = await api.post(url, { json: payload })

    return await handleResponse(resp)
  } catch (error) {
    throw await handleError(error)
  }
}
const postForm = async <T>(url: string, payload: FormData): Promise<T> => {
  try {
    const resp = await api.post(url, { body: payload })

    return await handleResponse(resp)
  } catch (error) {
    throw await handleError(error)
  }
}

const put = async <T>(url: string, payload: object): Promise<T> => {
  try {
    const resp = await api.put(url, { json: payload })
    return await handleResponse(resp)
  } catch (error) {
    throw await handleError(error)
  }
}

const patch = async <T>(url: string, payload: object): Promise<T> => {
  try {
    const resp = await api.patch(url, { json: payload })
    return await handleResponse(resp)
  } catch (error) {
    throw await handleError(error)
  }
}

const del = async <T>(url: string): Promise<T> => {
  try {
    const resp = await api.delete(url)
    return await handleResponse(resp)
  } catch (error) {
    throw await handleError(error)
  }
}

export { get, post, put, del, patch, postForm }
